.wrapper {
  max-width: 750px;
  width: 90%;
  margin: 0 auto;
  margin: auto;

  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 100px 0;
  padding-top: 150px;
}
.heading {
  max-width: 422px;
}
.container {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 48px;
  align-items: flex-start;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.infoContainer {
  background: linear-gradient(
    90deg,
    rgb(0, 67, 255, 0.15) 0%,
    rgb(20, 184, 166, 0.15) 100%
  );
  border: 1px solid var(--Primitive-800);
  border-radius: 12px;
  gap: 80px;

  break-inside: avoid;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  max-width: 280px;
  width: 100%;
}
.message {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  border: 1px solid var(--Base-800);
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--Primitive-900);
  color: var(--Text-Primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-overflow: ellipsis;
  width: 100%;
  outline: none;
}
.button {
  margin-top: auto;
}
.infoContainer {
  background: linear-gradient(
    90deg,
    rgb(0, 67, 255, 0.15) 0%,
    rgb(20, 184, 166, 0.15) 100%
  );
  border: 1px solid var(--Primitive-800);
  border-radius: 12px;
  gap: 80px;

  break-inside: avoid;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  max-width: 280px;
  width: 100%;
}
.title {
  padding-bottom: 12px;
}
.item {
  color: var(--Primitive-300);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media only screen and (max-width: 991px) {
  .wrapper {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    gap: 36px;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .infoContainer {
    max-width: 100%;
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding-top: 125px;
  }
}
@media only screen and (max-width: 520px) {
  .inputContainer {
    grid-template-columns: 1fr;
  }
}
