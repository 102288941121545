.wrapper {
  background: linear-gradient(90deg, #070910 0%, #072235 50%, #070b0c 100%);
  padding: 80px 0;
  background: url(../../../images/home/heroBg.png);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 70px;
  z-index: 1;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.heading {
  line-height: 100%;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
}
.serviceImg {
  width: 100%;
  display: block;
}

.titleContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
  width: 100%;
}
.title {
  min-height: 48px;
}
.addButton {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--Primitive-700);
}
.plusIcon {
  color: var(--Primitive-500);
  font-size: 18px;
}
.seeAllProductsButton {
  padding: 0;
  margin-right: auto;
  margin-left: 15px;
}
.button {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Base-950);
}
.nextButton,
.previousButton {
  padding: 10px 10px;
  margin: 0 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background: transparent;
  transform: translateY(calc(100% + 50px));
  position: absolute;
  bottom: 0px;

  z-index: 2;

  background: var(--Primitive-800);
  color: var(--Primitive-100);
  width: 40px;
  height: 40px;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.previousButton {
  right: 0px;
}
.previousButton:hover,
.nextButton:hover {
  transform: translateY(calc(100% + 50px));
}
.nextButton {
  right: 50px;
}
.arrow {
  font-size: 20px;
  margin: auto;
}
.activeButton {
  background: var(--Primitive-700);
  color: var(--Base-White);
}
.info {
  max-width: 420px;
}
@media only screen and (max-width: 1199px) {
  .feature {
    padding: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .header {
    flex-direction: column;
    align-items: start;
    gap: 24px;
  }

  .feature {
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
  .container {
    gap: 45px;
  }
  .headingContainer {
    gap: 18px;
  }
  .nextButton,
  .previousButton {
    width: 36px;
    height: 36px;
    transform: translateY(calc(100% + 30px));
  }
  .previousButton:hover,
  .nextButton:hover {
    transform: translateY(calc(100% + 30px));
  }
  .nextButton {
    right: 42px;
  }
  .arrow {
    font-size: 18px;
  }
}
