.inputContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  border: 1px solid var(--Primitive-800);
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--Primitive-900);
}

.searchIconInputContainer {
  padding: 12px 16px;
}

.searchIcon {
  font-size: 20px;
  color: var(--Text-Secondary);
  margin-top: 2px;
  cursor: pointer;
}
.input {
  color: var(--Text-Primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  text-overflow: ellipsis;
  width: 100%;
}

.close {
  color: var(--Text-Secondary);
  cursor: pointer;
  font-size: 18px;
}
.eye {
  cursor: pointer;
  max-width: 20px;
  width: 100%;
}
