.wrapper {
  padding: 80px 0;
  overflow-x: hidden;
}
.container {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 80px;
  align-items: center;
}
.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  position: relative;
}
.infoWrapper::before {
  content: " ";
  position: absolute;
  height: 100%;
  right: 0;
  width: 1px;

  top: 50%;
  transform: translate(calc(-50% + 40px), -50%);
  background: linear-gradient(
    to bottom,
    #07080a 0%,
    #2b2c2e 49.8%,
    #07080a 100%
  );
}
.info {
  width: 100%;
  margin: 0 auto;
}
.button {
  box-shadow: 0 4px 8px rgba(20, 184, 166, 0.3);
  margin: 8px 0;
}
.sliderContainer {
  width: 100%;
  grid-column: 1/-1;
  max-width: 1199px;
}
.plusIcon {
  transform: translateY(2px);
  font-size: 20px;
}
.features {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.feature {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-radius: 16px;
  border: 1px solid var(--Primitive-800);
  background: var(--Bg-primary);
}

@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
}
@media only screen and (max-width: 575px) {
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .features {
    grid-template-columns: 1fr;
  }
}
