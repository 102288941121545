.wrapper {
  padding: 80px 0;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  background: url(../../../images/home/heroBg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
}
.step {
  border-radius: var(--radius-16, 16px);

  background: var(--Primitive-950);
  border-radius: 16px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.heading {
  max-width: 328px;
  margin: 0 auto;
  line-height: 100%;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 10px;
  border: 1px solid var(--Primitive-700);
  background: var(--Primitive-800);
  margin-bottom: 12px;
}
.icon {
  max-width: 28px;
  display: block;
}
.title {
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .step {
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
}
@media only screen and (max-width: 991px) {
  .steps {
    grid-template-columns: repeat(2, 1fr);
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
  }
  .step {
    padding: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    gap: 32px;
  }
  .steps {
    grid-template-columns: 1fr;
    max-width: 400px;
  }
  .step {
    padding: 20px;
  }
}
