.items {
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  padding: 0 12px;
  border-top: 1px solid rgba(31, 32, 34, 0);
  border-bottom: 1px solid rgba(31, 32, 34, 0);
  background: linear-gradient(
    90deg,
    rgba(15, 16, 18, 0) 0%,
    #0f1012 50%,
    rgba(15, 16, 18, 0) 100%
  );
}
.img {
  max-width: 48px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.1);
}
.img {
  transition: width 0.3s ease, height 0.3s ease;
}
