.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 80px 0;
  border-radius: 16px;
  /* background: url(../../../images/home/getStartedBg.png); */
  background: linear-gradient(
    90deg,
    rgb(0, 67, 255, 0.15) 0%,
    rgb(20, 184, 166, 0.15) 100%
  );
  border: 1px solid var(--Primitive-700);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.heading {
  line-height: 100%;
}
.infoContainer {
  max-width: 409px;
  width: 85%;
  margin: 0 auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.calenderIcon {
  max-width: 20px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
  .container {
    padding: 10% 0;
  }
  .infoContainer {
    gap: 24px;
  }
  .heading {
    line-height: 110%;
  }
}
