.wrapper {
  overflow-y: auto;

  overflow-y: auto;
  height: calc(100vh);
  padding: 15px 15px;
  padding-bottom: 50px;
  margin: 0 5px;
  margin-top: 15px;
  position: relative;
}
.closeContainer {
  border-radius: 50%;
  border: 1px solid var(--Primitive-700);
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  margin-left: auto;
  background: var(--Primitive-900);
}
.closeIcon {
  color: var(--Primitive-500);
  font-size: 18px;
}

.container {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 60px 0;
  padding-top: 24px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.info {
  color: var(--Primitive-300);
  line-height: 28px;
}
.termsAndConditon {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  display: flex;
  gap: 5px;
  align-items: flex-start;
}
.description {
  color: var(--Primitive-400);
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .container {
    gap: 32px;
    padding: 48px 0;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .header {
    gap: 28px;
  }
}
