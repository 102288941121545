.wrapper {
  padding: 80px 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;

  padding: 80px 64px;
  border-radius: 16px;
  /* background: url(../../../images/home/getStartedBg.png); */
  background: linear-gradient(
    90deg,
    rgb(0, 67, 255, 0.15) 0%,
    rgb(20, 184, 166, 0.15) 100%
  );
  border: 1px solid var(--Primitive-700);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-x: hidden;
}
.heading {
  line-height: 110%;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.list {
  display: flex;

  gap: 8px;
}
.checkMark {
  color: var(--Primary-Default);
  margin-top: 3px;
}
.buttonContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 12px;
  margin-top: 24px;
}
.calenderIcon {
  max-width: 20px;
  width: 100%;
}
.img {
  width: 100%;
  max-width: 280px;
  display: block;
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr;
    gap: 60px;
    padding: 40px;
  }
  .img {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
}
@media only screen and (max-width: 520px) {
  .container {
    padding: 32px 24px;
  }
  .buttonContainer {
    gap: 8px;
  }
  .button {
    padding: 8px 10px;
  }
  .img {
    max-width: 200px;
  }
}
