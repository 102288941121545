.footer {
  margin-top: auto;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: auto;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  gap: 48px 0px;
  position: relative;
  padding-bottom: 30px;
}

.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  padding: 0 48px;
  position: relative;
}
.companyContainer {
  padding-left: 0;
}
.itemContainer::before {
  content: " ";
  position: absolute;
  height: 100%;
  right: 0;
  width: 1px;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
  background: linear-gradient(
    to bottom,
    #2b2c2e 40%,

    #07080a 100%
  );
}
.socialContainer {
  padding-right: 0;
}
.socialContainer::before {
  display: none;
}
.heading {
  padding-bottom: 6px;
}
.item {
  color: var(--Primitive-300);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
}
.product {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
}
.productInfo {
  color: var(--Primitive-300);
}
.item:hover {
  transform: translateY(-2px);
}
.socialIconContainer {
  border-radius: 4px;
  border: 1px solid var(--Primitive-700);
  background: var(--Primitive-800);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialIcon {
  font-size: 18px;
  color: var(--Primitive);
}
.button {
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  border: 1px solid var(--Primitive-700);
  background: var(--Primitive-800);
  margin-bottom: 12px;
}
.icon {
  max-width: 24px;
  display: block;
}

.copyRightContainer {
  border-top: 1px solid var(--Primitive-700);
  padding: 28px 0;
}
.copyRight {
  color: var(--Primitive-300);
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr) auto;
  }
  .talkToUs::before {
    display: none;
  }
  .talkToUs {
    padding-right: 0;
  }
  .socialContainer {
    padding: 0;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .products::before {
    display: none;
  }
  .products {
    padding-right: 0;
    padding-left: 32px;
  }

  .itemContainer {
    padding: 0 32px;
  }
  .companyContainer {
    padding-left: 0;
  }
  .talkToUs {
    padding-left: 0;
    padding-right: 32px;
  }
  .talkToUs::before {
    display: block;
  }
  .socialContainer {
    padding-left: 32px;
  }
}
@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 640px) {
  .copyRightContainer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    grid-template-columns: 1fr;
    max-width: 400px;
    width: 95%;
    margin: 0 auto;
    gap: 32px 0;
  }
  .itemContainer {
    padding: 0;
    padding-bottom: 32px;
  }
  .itemContainer::before {
    width: 100%;
    height: 1px;
    right: auto;
    top: auto;
    bottom: 0;
  }
  .socialContainer {
    padding-bottom: 0;
  }
  .socialContainer::before {
    display: none;
  }
}
