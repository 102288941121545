.wrapper {
  padding: 80px 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 580px;
  width: 100%;
}

/* .guiInterfactImgae {
  max-width: 1024px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../../images/home/getStartedBg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
  margin-top: 48px;
} */
.heading {
  line-height: 100%;
}
.title {
  opacity: 0.2px;
  max-width: 90%;
  margin: 0 auto;
}
.img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
  .cotainer {
    gap: 60px;
  }
  .heading {
    line-height: 115%;
  }
  /* .guiInterfactImgae {
    height: 300px;
  } */
}
