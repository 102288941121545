.wrapper {
  background: url(../../../images/home/heroBg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 80px 0;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.heading {
  line-height: 100%;
  padding-bottom: 80px;
}

.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
}
.card {
  border-radius: var(--radius-16, 16px);
  background: var(--Primitive-950);
  border-radius: 16px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  height: 100%;
}
.integration,
.networkInfoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  grid-column: span 2;
}
.integration {
  align-items: flex-end;
}

.content,
.eventsUpdate,
.networkInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  height: 100%;
}

.heading {
  max-width: 447px;
  margin: 0 auto;
  line-height: 100%;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 10px;
  border: 1px solid var(--Primitive-700);
  background: var(--Primitive-800);
  margin-bottom: 12px;
}
.icon {
  max-width: 28px;
  display: block;
}
.networkInfo {
  gap: 12px;
}
.list {
  display: flex;
  gap: 8px;
}
.myIcon {
  font-size: 18px;
  color: var(--Primitive-200);
  margin-top: 2px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.info {
  color: var(--Primitive-400);
}
.img {
  width: 100%;
  max-width: 261px;
  margin: 0 auto;
  display: block;
}
.features {
  padding: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
}
.feature {
  border-radius: 30px;
  border: 1px solid var(--Primitive-700);
  background: rgba(31, 32, 34, 0.48);
  box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(calc(var(--blur-40, 40px) / 2));
  padding: 6px 8px;
  white-space: nowrap;
}
.button {
  padding: 6px 12px;
  white-space: nowrap;
  font-size: 14px;
  margin: 24px 0;
}
.amount {
  font-weight: 600;
}
.linkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
@media only screen and (max-width: 1199px) {
  .card {
    padding: 24px;
  }
}

@media only screen and (max-width: 991px) {
  .card {
    padding: 20px;
  }
  .cards {
    grid-template-columns: 1fr 1fr;
  }
  .integration,
  .networkInfoContainer {
    grid-column: span 2;
    gap: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }

  .heading {
    line-height: 115%;
    padding-bottom: 32px;
  }
  .cards {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin: 0 auto;
  }
  .integration,
  .networkInfoContainer {
    grid-template-columns: 1fr;
    grid-column: 1;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    gap: 32px;
  }
}
