.modalOverlay {
  background: rgba(7, 8, 10, 0.5);

  /* Background-Blur/32 */
  backdrop-filter: blur(calc(var(--blur-32, 32px) / 2));
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1500;
  transition: 0.3s ease-in-out;
  pointer-events: none;
  cursor: pointer;
}

.modal {
  display: grid;
  grid-template-columns: 1fr;
  position: fixed;

  left: 50%;

  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
  z-index: 1501;

  /* my */
  margin: 0 auto;
  max-width: 960px;
  width: 95%;
  max-height: calc(100vh - 5%);
  min-height: 250px;
  height: max-content;

  border-radius: 24px 24px 0 0;
  background: var(--Primitive-900);
  box-shadow: 0px -40px 64px -16px rgba(0, 0, 0, 0.2);
  bottom: 0px;
}

.modalOverlay.active {
  opacity: 1;
  pointer-events: all;
}

.modal.active {
  pointer-events: all;
  opacity: 1;
}
.close {
  border-radius: var(--radius-9999, 9999px);
  border: 1px solid var(--primitive-neutral-700, #1f2022);
}
