.text {
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  font-size: 14px;
  color: var(--Text-Primary);
}
.xs {
  font-size: 12px;
}
.sm {
  font-size: 14px;
}
.md {
  font-size: 14px;
}
.base {
  font-size: 16px;
}
.lg {
  font-size: 18px;
}
.xl {
  font-size: 20px;
}
.xl2 {
  font-size: 24px;
}
.xl3 {
  font-size: 30px;
}
.xl4 {
  font-size: 32px;
}
.primaryDefault {
  color: var(--Primary-Default);
}

.successDefault {
  color: var(--Success-Default);
}
.primary {
  color: var(--Text-Primary);
}
.baseWhite {
  color: var(--Base-White);
}
.textTeritary {
  color: var(--Text-Teritary);
}
.textPrimitive {
  color: var(--Text-Primitive);
}
.headingColor {
  color: var(--Heading-Color);
}
.secondary {
  color: var(--Text-Secondary);
}
.textPlaceholer {
  color: var(--Text-Placeholder);
}
.textTeritary {
  color: var(--Text-Teritary);
}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.bold {
  font-weight: 700;
}
.semiBold {
  font-weight: 500;
}
.font600 {
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .lg {
    font-size: 18px;
  }
}
@media only screen and (max-width: 520px) {
  .lg {
    font-size: 16px;
  }
}
