.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.infoWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.infoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 64px;
  height: 100%;
}
.infoContainer {
  border-top: none;
}
.infoContainer:nth-child(1) {
  border-top: none;
}
.even {
  border-bottom: 1px solid var(--Primitive-800);
}
.odd {
  border-bottom: 1px solid var(--Primitive-800);
}
.odd:first-child {
  border-top: none;
  border-right: 1px solid var(--Primitive-800);
}

.odd:last-child {
  border-left: 1px solid var(--Primitive-800);
}
.header {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
/* .odd:first-child::before,
.odd:last-child:before {
  content: " ";
  position: absolute;
  height: 100%;
  right: 0;
  width: 1px;

  top: 50%;
  transform: translate(calc(-50% + 64px), -50%);
  background: var(--Primitive-800);
}
.odd:last-child:before {
  left: 0;
  right: auto;
  transform: translate(calc(-50% - 64px), -50%);
}
.odd::after {
  content: " ";
  position: absolute;
  height: 1px;
  right: 0;
  width: 100%;
  top: auto;
  bottom: 0;
  background: linear-gradient(
    to right,
    #07080a 0%,
    #2b2c2e 49.8%,
    #07080a 100%
  );
} */
.even {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 128px;
  align-items: center;
}
.img {
  width: 100%;
  display: block;
}

@media only screen and (max-width: 1199px) {
  .infoContainer {
    padding: 48px;
  }
}
@media only screen and (max-width: 991px) {
  .infoWrapper {
    grid-template-columns: 1fr;
    max-width: 600px;
    margin: 0 auto;
  }
  .even {
    grid-template-columns: 1fr;
    border: none;
  }
  .infoContainer {
    border: none;
  }
  .odd:first-child {
    border: none;
  }

  .odd:last-child {
    border: none;
  }
  .img {
    max-width: 400px;
    margin: 0 auto;
  }
  .infoContainer {
    padding: 48px 0;
    border-bottom: 1px solid var(--Primitive-800);
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 50px 0;
    padding-top: 0;
  }
}
