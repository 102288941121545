.wrapper {
  position: fixed;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
  transition: 0.3s;
  border: 1px solid transparent;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-radius: var(--radius-8, 8px);
  border: 1px solid #1f2022;
  background: rgba(23, 24, 26, 0.8);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.32);
  backdrop-filter: blur(calc(var(--blur-24, 24px) / 2));
  padding: 16px;
}

.logo {
  max-width: 160px;
}
.navItems {
  display: flex;
  align-items: center;
  gap: 30px;
}
.navItem {
  color: var(--Text-Secondary);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 162.5%;
  cursor: pointer;
  text-transform: capitalize;
}
.navActive {
  color: var(--Text-White);
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.icon {
  display: none;
}
.calenderIcon {
  max-width: 20px;
  width: 100%;
}
.button {
  margin-left: auto;
}
.mobileButton {
  display: none;
}
.products {
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    border-radius: var(--radius-8, 8px);
    border: 1px solid #1f2022;
    background: rgba(23, 24, 26, 0.8);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.32);
    backdrop-filter: blur(calc(var(--blur-24, 24px) / 2));
  }
  .header {
    padding-left: 0;
    padding-right: 0;
    background: none;
    box-shadow: none;
    border: none;
    backdrop-filter: none;
  }
  .logo {
    max-width: 140px;
  }
  .navItems {
    flex-direction: column;
    gap: 24px;
    height: 100vh;
    overflow-y: auto;
    background: var(--Primitive-950);
    transform: translateX(250%);
    transition: 0.3s;
    position: fixed;
    top: 0px;
    right: 0;
    padding-top: 80px;
    padding-bottom: 24px;
    max-width: 320px;
    width: 100%;
    min-height: 100vh;
    z-index: 100;
  }
  .sidebar {
    transform: translateX(0);
    right: 0;
  }
  .buttonContainer {
    align-items: center;
    gap: 15px;
  }
  .button {
    font-size: 14px;
    padding: 10px 15px;
  }
  .icon {
    display: block;
    cursor: pointer;
    color: var(--Base-White);
    font-size: 28px;
  }
  .closeIcon {
    position: fixed;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .button {
    display: none;
  }
  .mobileButton {
    display: flex;
  }
}
