.itemContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  padding: 20px 0;

  min-width: 250px;

  height: 0;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 30px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease-in-out;

  border-radius: 12px;
  background: rgba(23, 24, 26, 0.95);

  /* Background-Blur/24 */
  backdrop-filter: blur(12px);
  padding: 20px;
}
.itemContainer.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

.item {
  color: var(--Primitive-300);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 162.5%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  gap: 8px;
}
.product {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;

  padding-bottom: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  width: 100%;
}
.productInfo {
  color: var(--Primitive-300);
}
.item:hover {
  transform: translateY(-2px);
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 10px;
  border: 1px solid var(--Primitive-700);
  background: var(--Primitive-800);
}
.icon {
  max-width: 24px;
  display: block;
}

.button {
  background: rgb(255, 255, 255, 0.05);
  color: var(--Text-Teritary);
}
