.wrapper {
  padding: 80px 0;
  overflow-x: hidden;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  max-width: 674px;
  margin: 0 auto;
}
.heading {
  max-width: 286px;
  margin: 0 auto;
  line-height: 100%;
}

.button {
  box-shadow: 0 4px 8px rgba(20, 184, 166, 0.3);
  margin: 8px 0;
}
.sliderContainer {
  padding: 12px 12px;
  border-top: 1px solid rgba(31, 32, 34, 0);
  border-bottom: 1px solid rgba(31, 32, 34, 0);
  background: linear-gradient(
    90deg,
    rgba(15, 16, 18, 0) 0%,
    #0f1012 50%,
    rgba(15, 16, 18, 0) 100%
  );
  width: 100%;
}
.plusIcon {
  transform: translateY(2px);
  font-size: 20px;
}
.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
.feature {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid var(--Primitive-900);
  background: var(--Bg-primary);
  border: 1px solid var(--Primitive-700);
}

@media only screen and (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
  .heading {
    line-height: 115%;
  }
  .feature {
    padding: 12px;
  }
  .title {
    font-size: 28px;
  }
  .info {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
  .heading,
  .tagline {
    text-align: center;
  }
}
@media only screen and (max-width: 520px) {
  .feature {
    gap: 4px;
    padding: 10px;
  }
  .title {
    font-size: 24px;
  }
  .info {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  .title {
    font-size: 20px;
  }
}
