.wrapper {
  min-height: 100vh;
  padding: 100px 0;
  padding-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #070910 0%, #072235 50%, #070b0c 100%);
  background: url(../../../images/home/heroBg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.infoContainer {
  max-width: 616px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.leftArt,
.rightArt {
  max-width: 152px;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.leftArt {
  left: 0;
}
.rightArt {
  right: 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.calenderIcon {
  max-width: 20px;
  width: 100%;
}
.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  margin-top: 48px;
}
.downArrow {
  color: var(--Base-White);
  font-size: 28px;
}
@media only screen and (max-width: 991px) {
  .link {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .leftArt,
  .rightArt {
    display: none;
  }
  .wrapper {
    padding-top: 125px;
  }
}
