.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.aboutUs {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 128px;
}
.img {
  width: 100%;
  display: block;
  max-width: 392px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
}
.button {
  padding: 0;
}

@media only screen and (max-width: 991px) {
  .aboutUs {
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .img {
    max-width: 300px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
    padding-top: 0;
  }
}
@media only screen and (max-width: 575px) {
  .container {
    gap: 48px;
  }
  .button {
    margin: 0 auto;
  }
}
