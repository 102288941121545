@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Inter";
}
:root {
  --Primary-Default: #3b82f6;
  --Heading-Primary-Color: #fff;
  --Text-White: #fff;
  --Bg-Light: #e9eaec;
  --Text-Primary: #a9aaac;
  --Text-Secondary: #898a8c;
  --Text-Teritary: #c9cacc;
  --Text-Primitive: #a9aaac;
  --Dark-Light: #2b2c2e;
  --Base-White: #fff;
  --Primitive-100: #c9cacc;
  --Primitive-200: #a9aaac;
  --Primitive-300: #898a8c;
  --Primitive-400: #656668;
  --Primitive-500: #4d4e50;
  --Primitive-700: #1f2022;
  --Primitive-800: #17181a;
  --Primitive-900: #0f1012;
  --Primitive-950: #07080a;

  --Bg-Primary: #0f1012;
}

body {
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: #07080a;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
}
input {
  border: none;
  outline: none;
  background: transparent;
}
button,
a {
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
}
button:hover,
a:hover {
  opacity: 0.8;
  transform: translateY(-3px);
}
.container {
  max-width: 1080px;
  width: 90%;
  margin: 0 auto;
}
.highlight {
  color: var(--Text-Teritary);
}
::-webkit-scrollbar {
  width: 3px;
  background: rgba(250, 250, 250, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: var(--Primitive-400);
  border-radius: 4px;
}
.overflow {
  scrollbar-width: thin;
  scrollbar-color: var(--Primary-Default) rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari browsers */
.overflow::-webkit-scrollbar {
  width: 1px;
}
.overflow::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.overflow::-webkit-scrollbar-thumb {
  background: var(--Primary-Default);
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* slick slider */
.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
  height: 100% !important;
}
.slick-track {
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
