.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
}
.heading {
  max-width: 479px;
  margin: 0 auto;
  width: 100%;
  line-height: 110%;
}
.features {
  display: grid;
  grid-template-columns: 1fr;
  gap: 64px;
  width: 100%;
  max-width: 674px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.feature {
  border-radius: var(--radius-16, 16px);
  border: 1px solid #17181a;
  background: var(--Bg-Primary);
  border-radius: 16px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  position: sticky;
  position: -webkit-sticky;
}
.icon {
  margin-bottom: 12px;
  max-width: 64px;
  display: block;
}
.heading {
  width: 100%;
}
.title {
  position: relative;
  padding-bottom: 28px;
}
.title::before {
  content: " ";
  position: absolute;
  height: 1px;
  right: 0;
  width: 100%;

  bottom: 0;
  background: linear-gradient(
    to right,
    #2b2c2e 0%,

    #07080a 100%
  );
}
.info {
  padding-top: 28px;
}
@media only screen and (max-width: 1199px) {
  .feature {
    padding: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    gap: 48px;
  }
  .feature {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
}

@media only screen and (max-width: 575px) {
  .feature {
    padding: 20px;
  }
}
