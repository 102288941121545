.wrapper {
  padding: 80px 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  width: 100%;
}
.feature {
  border-radius: var(--radius-16, 16px);
  border: 1px solid #17181a;
  background: var(--Bg-Primary);
  border-radius: 16px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.icon {
  margin-bottom: 12px;
  max-width: 64px;
  display: block;
}
.heading {
  width: 100%;
  min-height: 48px;
}
@media only screen and (max-width: 1199px) {
  .features {
    grid-template-columns: repeat(3, 1fr);
  }
  .feature {
    padding: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 48px 0;
  }
}
@media only screen and (max-width: 991px) {
  .features {
    grid-template-columns: repeat(2, 1fr);
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
  }
  .feature {
    padding: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .features {
    grid-template-columns: 1fr;
    max-width: 400px;
  }
  .feature {
    padding: 20px;
  }
}
